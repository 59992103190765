
import {
  apiProxyUserList,
  apiProxyUserDelete,
} from "@/api/user";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import ExportData from "@/components/export-data/index.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging } from "@/utils/util";
import DatePicker from "@/components/date-picker.vue";
@Component({
  components: {
    lsPagination,
    ExportData,
    LsDialog,
    DatePicker,
  },
})
export default class SetMealList extends Vue {
  /** S Data **/

  apiProxyUserList = apiProxyUserList;

  pager: RequestPaging = new RequestPaging();

  searchObj: Object = {
    status: "",
    shop_info: "",
    start_time: "",
    end_time: "",
  };
  /** E Data **/

  /** S Methods **/

  // 获取团购列表
  getSetMealLists(): void {
    this.pager.request({
      callback: apiProxyUserList,
      params: {
        ...this.searchObj,
      },
    });
  }

  // 修改团购状态
  async shopSwitchStatusEdit(status: number, id: number): Promise<void> {
    // await apiSetMealSwitchStatus({ id, status: status });
    this.getSetMealLists();
  }

  // 删除团购
  async onSetMealDelete(row: any): Promise<void> {
    await apiProxyUserDelete({ id: row.id });
    this.getSetMealLists();
  }

  // 重置搜索
  reset(): void {
    Object.keys(this.searchObj).map((key) => {
      this.$set(this.searchObj, key, "");
    });
    this.getSetMealLists();
  }

  // 去编辑团购
  toSetMealEdit(id: number | any): void {
    this.$router.push({
      path: "/daili/form",
      query: {
        id: id,
      },
    });
  }

  /** E Methods **/

  /** S ods **/
  created() {
    this.getSetMealLists();
  }
}
